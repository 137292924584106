/*
|--------------------------------------------------------------------------
|-------------------------| FUNCTIONS ASIDE |------------------------------
|--------------------------------------------------------------------------
*/

import { handleAddOrRemoveClass } from "./functions-helpers";

/* --------------------------------------------------------------------------
| FUNCTION QUE SELECIONA O ASIDE...
|-------------------------------------------------------------------------- */
export const handleSelectedAside = () => {
    let asideElement = document.querySelector('.aside'); // Seleciona o aside...
    return asideElement;
}

/* --------------------------------------------------------------------------
| FUNCTION QUE ASIDE TOGGLE, ALTERNA ENTRE ABRIR OU FECHAR...
|-------------------------------------------------------------------------- */
export const handleToggleAside = () => {
    let elem = handleSelectedAside();
    (elem.classList.contains('aside--show')) ? handleToggleAsideOpenOrClose('close', elem) : handleToggleAsideOpenOrClose('open', elem);
}

/* --------------------------------------------------------------------------
| FUNCTION QUE ABRE OU FECHA O ASIDE...
|-------------------------------------------------------------------------- */
export const handleToggleAsideOpenOrClose = (type, element) => {
	if (type === 'open') {
		handleAddOrRemoveClass(element, 'add', 'aside--show');
		handleAddOrRemoveClass(element, 'remove', 'aside--close');

	} else if (type === 'close') {
		handleAddOrRemoveClass(element, 'remove', 'aside--show');
		handleAddOrRemoveClass(element, 'add', 'aside--close');
	}
}

/* --------------------------------------------------------------------------
| FUNCTION...
|-------------------------------------------------------------------------- */
export const handleOnlyOpenAside = () => {
    let elem = handleSelectedAside();
    (elem.classList.contains('aside--show')) ? null : handleToggleAsideOpenOrClose('open', elem);
}
