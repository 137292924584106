/*
|--------------------------------------------------------------------------
|--------------------------| FUNCTIONS USERS |-----------------------------
|--------------------------------------------------------------------------
*/

/* --------------------------------------------------------------------------
| FUNCTION QUE PEGA AS INICIAIS DO NOME DO USUÁRIO E USA COMO UMA IMAGEM...
|-------------------------------------------------------------------------- */
export const handleImageLetterUser = (element) => {
    let elementChildren = element.querySelector('.letterFlagChildren'); // Pega o elemento...
    let elementChildrenName = elementChildren.innerText; // Pega o nome do usuário...
    let letterImage = elementChildrenName.substring(0, 2); // Pega apenas as duas primeiras letras...

    elementChildren.innerHTML = letterImage; // Envia para o html apenas as duas letras...
}

/* --------------------------------------------------------------------------
| FUNCTION QUE ADICIONA UMA COR DE FUDO ALEATÓRIA PARA A S INICIAIS DO USUÁRIO...
|-------------------------------------------------------------------------- */
export const handleImageLetterUserBackground = (element) => {
    let colors = ['#8A43DB', '#A760F6', '#D374F3']; // Array de cores...
    let elementColor = colors[Math.floor(Math.random() * colors.length)]; // Selecionar cor aleatório dentro do array...

    element.style.backgroundColor = `${elementColor}`;
    element.style.color = '#FFFFFF';
}
