/* --------------------------------------------------------------------------
|--------------------------- FILE HEAD JAVASCRIPT ---------------------------
|-------------------------------------------------------------------------- */

import { handleVerifyElementExists } from './functions/functions-helpers';
import { handleModalClose, handleShowModal } from './functions/functions-modals';
import { handleModalDeleteConfirmation } from './functions/functions-switch-alerts';
import { handleAlertTimeOutClose } from './functions/functions-alerts';
import { handleToggleTheme } from './functions/functions-themes';
import { handleImageLetterUser } from './functions/functions-users';
import { handleToggleAsideOpenOrClose, handleSelectedAside } from './functions/functions-asides';
import { handleSelectCorrespondingDropdown } from './functions/functions-dropdowns';

/* --------------------------------------------------------------------------
|-------------------------------- USERS ---------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| PEGAR PRIMEIRA E SEGUNDA LETRA DO NOME DO USUÁRIO...
|-------------------------------------------------------------------------- */
const imageLetter = document.querySelectorAll('.letterFlag');
handleVerifyElementExists(imageLetter, () => {
    imageLetter.forEach((element) => {
        handleImageLetterUser(element);
    });
});

/* --------------------------------------------------------------------------
|--------------------------------| ASIDE |-----------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| BUTTON - CLOSE ASIDE...
|-------------------------------------------------------------------------- */
const buttonAsideCloseOver = document.querySelector('.buttonAsideClose');
handleVerifyElementExists(buttonAsideCloseOver, () => {
    buttonAsideCloseOver.addEventListener('click', () => {
        handleToggleAsideOpenOrClose('close', handleSelectedAside());
    });
});

/* --------------------------------------------------------------------------
|-------------------------------- MODAL ---------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| FECHAR MODAL...
|-------------------------------------------------------------------------- */
const modalClose = document.querySelectorAll('.handleModalClose');
handleVerifyElementExists(modalClose, () => {
    modalClose.forEach((element) => {
        element.addEventListener('click', (event) => {
            handleModalClose(event);
        });
    });
});

/* --------------------------------------------------------------------------
|--------------------------------- ALERTS ----------------------------------
|-------------------------------------------------------------------------- */

const closesAlerts = document.querySelectorAll('.alert__button-close');
handleVerifyElementExists(closesAlerts, () => {
    closesAlerts.forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();

            let button = event.currentTarget;
            let alert = button.closest('article.alert');

            alert.classList.remove('alert--show');
        });
    });
});

/* --------------------------------------------------------------------------
| CLOSE ALERTS POR TEMPO...
|-------------------------------------------------------------------------- */
window.addEventListener('load', () => {
    const closeAlertForTime = document.querySelectorAll('.alert.alert--timeout');
    handleVerifyElementExists(closeAlertForTime, () => {
        closeAlertForTime.forEach((element) => {
            handleAlertTimeOutClose(element, 5000);
        });
    });
});

/* --------------------------------------------------------------------------
|----------------------------------| FILES |--------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| ABRE MODAL PARA DELETAR ARQUIVOS...
| ATA, PROPOSIÇÃO
|-------------------------------------------------------------------------- */
const showModalDeleteFiles = document.querySelectorAll('.button-modal-delete-files');
handleVerifyElementExists(showModalDeleteFiles, () => {
    showModalDeleteFiles.forEach(element => {
        element.addEventListener('click', (event) => {
            let uri = event.currentTarget.getAttribute('data-uri');
            handleShowModal(event, uri);
        });
    });
});

/* --------------------------------------------------------------------------
|-------------------------------- PRINTS ---------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| EVENTO DE IMPRESSÃO...
|-------------------------------------------------------------------------- */
let print = document.querySelector('.print_button');
handleVerifyElementExists(print, () => {
    print.addEventListener('click', () => {
        window.print();
    });
});

/* --------------------------------------------------------------------------
|-------------------------------- THEMES ---------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| TOGGLE THEME COLOR DARK OR LIGHT...
|-------------------------------------------------------------------------- */
const toggleTheme = document.querySelector('#switchThemeTrigger');
handleVerifyElementExists(toggleTheme, () => {
    toggleTheme.addEventListener('click', (event) => {
        handleToggleTheme(event);
    });
});

/* --------------------------------------------------------------------------
|-------------------------------- SWITCH ALERT ---------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| DELETAR ELEMENTOS USANDO SWITCHALERT...
|-------------------------------------------------------------------------- */
const buttonDeleteElement = document.querySelectorAll('.buttonDelete');
handleVerifyElementExists(buttonDeleteElement, () => {
    buttonDeleteElement.forEach((element) => {
        element.addEventListener('click', handleModalDeleteConfirmation);
    });
});

/* --------------------------------------------------------------------------
|----------------------------| BUTTON DROPDOWN |-----------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| ABRE E FECHA O BUTTON MENU DROPDOWN ...
|-------------------------------------------------------------------------- */
const dropdownButtons = document.querySelectorAll(".dropdown-button");
handleVerifyElementExists(dropdownButtons, () => {
	dropdownButtons.forEach((element) => {
		element.addEventListener("click", (event) => {
			handleSelectCorrespondingDropdown(event);
		});
	});
});




/* --------------------------------------------------------------------------
| ABRE E FECHA O BUTTON DE VOTAÇÃO DO VEREADOR...
|-------------------------------------------------------------------------- */
const toggleCouncilorVote = document.querySelectorAll(".action-toggle-councilor-trigger");
handleVerifyElementExists(toggleCouncilorVote, () => {
	toggleCouncilorVote.forEach((element) => {
		element.addEventListener("click", (event) => {
			let father = element.closest('div.councilor-trigger-options');

			if (father.classList.contains('councilor-trigger-options--show')) {
				father.classList.remove('councilor-trigger-options--show')
				father.classList.add('councilor-trigger-options--close')
			} else {
				father.classList.remove('councilor-trigger-options--close')
				father.classList.add('councilor-trigger-options--show')
			}
		});
	});
});

window.customAlert = (message, duration) => {
	var modalAlert = document.querySelector('.custom-alert');
	modalAlert.textContent = message;
	modalAlert.style.display = 'block';

	setTimeout(function () {
		modalAlert.style.display = 'none';
	}, duration);
}

document.addEventListener('DOMContentLoaded', function () {
	document.querySelectorAll('.vote_button').forEach(button => {
		button.addEventListener('click', function () {
			localStorage.setItem('voteActionConfirmed', this.getAttribute('data-vote'));
		});
	});

	const voteActionConfirmed = localStorage.getItem('voteActionConfirmed');
	if (voteActionConfirmed) {
		localStorage.removeItem('voteActionConfirmed');
		let message = 'Seu voto foi registrado! ';
		customAlert(message, 10000);
	}
});
