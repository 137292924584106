/*
|--------------------------------------------------------------------------
|-----------------| FUNCTIONS SWITCH ALERT - DELETE |----------------------
|--------------------------------------------------------------------------
*/

// export const handleModalDeleteConfirmation = (event) => {
export function handleModalDeleteConfirmation(event) {
    event.preventDefault();

    const register = event.currentTarget;

    Swal.fire({
        title: `Tem certeza que deseja excluir este item?`,
        text: "Depois não será possível restaurar",
        icon: "warning",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        showCancelButton: true,
        dangerMode: true,

    }).then((willDelete) => {
        if (willDelete.isConfirmed) {
            const uri = register.dataset.uri;

            Swal.fire({
                title: "Aguarde um momento por favor.",
                text: "Excluindo item....",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: false
            });

            axios({
                method: 'delete',
                url: uri,
                responseType: 'json'

            }).then((response) => {
                Swal.fire({
                    title: "Pronto",
                    text: "Item exclúído com sucesso",
                    icon: "success",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                    buttons: false

                }).then(() => {
                    location.reload();
                });

            }).catch((reason) => {
                Swal.fire({
                    title: "Não é possível excluir esse item",
                    text: reason.response.data.error,
                    icon: "error",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                    buttons: false
                });
            });
        }
    });
}

// export const handleModalCancelQuorumConfirmation = (event) => {
export function handleModalCancelQuorumConfirmation(event) {
    event.preventDefault();

    const register = event.currentTarget;

    Swal.fire({
        title: `Tem certeza que deseja cancelar este item?`,
        text: "Depois não será possível restaurar",
        icon: "warning",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        showCancelButton: true,
        dangerMode: true,

    }).then((willDelete) => {
        if (willDelete.isConfirmed) {
            const uri = register.dataset.uri;

            Swal.fire({
                title: "Aguarde um momento por favor.",
                text: "Cancelando item....",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: false
            });

            axios({
                method: 'delete',
                url: uri,
                responseType: 'json'

            }).then((response) => {
                Swal.fire({
                    title: "Pronto",
                    text: "Item cancelado com sucesso",
                    icon: "success",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                    buttons: false

                }).then(() => {
                    location.replace('/quoruns');
                });

            }).catch((reason) => {
                Swal.fire({
                    title: "Não é possível excluir esse item",
                    text: reason.response.data.message,
                    icon: "error",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                    buttons: false
                });
            });
        }
    });
}

