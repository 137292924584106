/*
|--------------------------------------------------------------------------
|-------------------------| FUNCTIONS HELPERS |----------------------------
|--------------------------------------------------------------------------
*/

/* --------------------------------------------------------------------------
| FUNÇÃO QUE VERIFICA SE O ELEMENTO FOI SELECIONADO...
|-------------------------------------------------------------------------- */
export const handleVerifyElementExists = (element, callback) => {
	return (element) ? callback() : null;
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE FAZ REQUISIÇÃO...
|-------------------------------------------------------------------------- */
export const handleFetch = async (uri) => {
	let response = await fetch(uri);
	let data = await response.json();

	return data;
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE REMOVE DO SESSION OU LOCAL STORAGE..
|-------------------------------------------------------------------------- */
export const handleRemoveStorage = (type = 'local', element) => {
	(type === 'session')
		? sessionStorage.removeItem(element)
		: sessionStorage.removeItem(element);
}

/* --------------------------------------------------------------------------
| FUNCTION QUE LIMPA O HTML...
|-------------------------------------------------------------------------- */
export const handleCleanRender = (element) => {
	element.innerHTML = '';
}

/* --------------------------------------------------------------------------
| FUNCTION QUE ADICIONA OU REMOVE UMA CLASSE EM UM ELEMENTO...
|-------------------------------------------------------------------------- */
export const handleAddOrRemoveClass = (element, type, classe) => {
	(type === 'add') && element.classList.add(`${classe}`);
	(type === 'remove') && element.classList.remove(`${classe}`);
}

/* --------------------------------------------------------------------------
| FUNCTION QUE RECEBE UM ARRAY E ADICIONA OU REMOVE A CLASS DO ELEMENTO...
|-------------------------------------------------------------------------- */
export const handleLoopArrayAddOrRemoveClass = (elements, type, classe) => {
	elements.forEach(element => {
		if (type === 'add') {
			element.classList.add(classe);
		} else if (type === 'remove') {
			element.classList.remove(classe);
		} else {
			return false;
		}
	});
}

/* --------------------------------------------------------------------------
| FUNCTION QUE SELECIONA TODAS AS PÁGINAS DO PAINEL...
|-------------------------------------------------------------------------- */
export const handleSelectScreensOnPanel = () => {
	let pagesPanelArray = [];
	let pagePanelHome = document.querySelector('#section__panel__home');
	let pagePanelQuorum = document.querySelector('#section__panel__quorum');
	let pagePanelPropositionRead = document.querySelector('#section__panel__proposition__read');
	let pagePanelPropositionVote = document.querySelector('#section__panel__proposition__vote');
	let pagePanelPropositionVoteResult = document.querySelector('#section__panel__proposition__vote__result');
	// let pagePanelFrankWordSubscribe = document.querySelector('#section__panel__frank__word__subscribe');
	let pagePanelFrankWordEnrroled = document.querySelector('#section__panel__frank__word__enrolled');
	let pagePanelFrankWordSpeak = document.querySelector('#section__panel__speak');

	pagesPanelArray.push(pagePanelHome, pagePanelPropositionRead, pagePanelPropositionVote, pagePanelPropositionVoteResult, pagePanelQuorum, pagePanelFrankWordEnrroled, pagePanelFrankWordSpeak);

	return [pagesPanelArray];
}

/* --------------------------------------------------------------------------
| FUNCTION QUE SELECIONA TODAS AS PÁGINAS DO TIMER...
|-------------------------------------------------------------------------- */
export const handleSelectScreensOnTimer = () => {
	let pagesTimerArray = [];
	let pageTimerHome = document.querySelector('#section__timer__home');
	let pageTimerQuorum = document.querySelector('#section__timer__quorum');
	let pageTimerFrankWordSubscribe = document.querySelector('#section__timer__frank__word__subscribe');
	let pageTimerFrankWordSpeak = document.querySelector('#section__timer__speak');

	pagesTimerArray.push(pageTimerHome, pageTimerQuorum, pageTimerFrankWordSubscribe, pageTimerFrankWordSpeak);

	return [pagesTimerArray];
}

/* --------------------------------------------------------------------------
| FUNCTION QUE EXIBE A PÁGINA DESEJADA DO PAINEL E REMOVE AS DEMAIS...
|-------------------------------------------------------------------------- */
export const handleOpenScreenPagesOnPanel = (keepOpen) => {
	let [pagesPanelArray] = handleSelectScreensOnPanel();

	pagesPanelArray.map(element => {
			(element === keepOpen)
			? (element.classList.contains('none-element')) && element.classList.remove('none-element')
			: element.classList.add('none-element');
	});
}

/* --------------------------------------------------------------------------
| FUNCTION QUE EXIBE A PÁGINA HOME DO PAINEL E REMOVE AS DEMAIS...
|-------------------------------------------------------------------------- */
export const handleCloseScreenPagesOnPanel = (keepOpen) => {
	let [pagesPanelArray] = handleSelectScreensOnPanel();

	pagesPanelArray.map(element => {
		(element === keepOpen)
			? (!element.classList.contains('none-element')) && element.classList.add('none-element')
			: (element === pagesPanelArray[0])
				? element.classList.remove('none-element')
				: (element.classList.add('none-element'));
	});
}
