/*
|--------------------------------------------------------------------------
|-------------------| FUNCTIONS THEME DARK/LIGHT |-------------------------
|--------------------------------------------------------------------------
*/

const root = document.querySelector('root');
const body = document.querySelector('body');

const lightMode = {
    colorTextPrimary: '#11142D',
    colorTextSecondary: '#808191',
    colorBackgroundPrimary: '#FFFFFF',
    colorBackgroundSecondary: '#FCFEFE',
    colorBackgroundTertiary: 'rgba(#E4E4E4, 0.5)',
    colorShadow: 'inset -0.063rem 0 0 0 #E4E4E4',
    colorGlassmorphism: 'rgba(#11142D, 25%)',
}

const darkMode = {
    colorTextPrimary: '#FFFFFF',
    colorTextSecondary: '#808191',
    colorBackgroundPrimary: '#1F2128',
    colorBackgroundSecondary: '#242731',
    colorBackgroundTertiary: '#33353B',
    colorShadow: 'inset -0.063rem 0 0 0 rgb(228 228 228 / 10%)',
    colorGlassmorphism: 'rgba(#1F2128, 25%)',
}

/* --------------------------------------------------------------------------
| FUNCTION -> REPLACE NO NOME DA VARIAVEL DE JA PARA CSS
|-------------------------------------------------------------------------- */
export const handleTransformKey = (key) => {
    return "--" + key.replace(/([A-Z])/, "-$1").toLowerCase();
}

/* --------------------------------------------------------------------------
| FUNCTION -> LIDA COM AS CORES DO THEME...
|-------------------------------------------------------------------------- */
export const handleToggleColors = (colors) => {
    Object.keys(colors).map(key =>
        root.style.setProperty(handleTransformKey(key), colors[key])
    );
}

/* --------------------------------------------------------------------------
| FUNCTION -> ALTERNA ENTRE O TEMA CLARO E ESCURO...
|-------------------------------------------------------------------------- */
export function handleToggleTheme(event) {
    let theme = event.currentTarget;
    let themeIconSun = theme.querySelector('.switch__icon__sun');
    let themeNameSun = theme.querySelector('.switch__desc__sun');
    let themeIconMoon = theme.querySelector('.switch__icon__moon');
    let themeNameMoon = theme.querySelector('.switch__desc__moon');

    // (body.classList.contains('theme__dark'))
    //     ? handleThemeFalse(body, 'dark')
    //     : handleThemeTrue(body, 'dark');

    // const elem = handleGetStyle(html, --theme-dark);

    // if (elem == true) {
    //     handleThemeFalse(html, 'dark');
    //     handleThemeTrue(html, 'light');
    // } else {
    //     handleThemeFalse(html, 'light');
    //     handleThemeTrue(html, 'dark');
    // }
}

/* --------------------------------------------------------------------------
| FUNCTION -> PEGA O STYLE DO ELEMENTO...
|-------------------------------------------------------------------------- */
export const handleGetStyle = (element, style) => {
    window.getComputedStyle(element).getPropertyValue(style);
}

/* --------------------------------------------------------------------------
| FUNCTION -> SETA O THEMA COMO VERDADEIRO...
|-------------------------------------------------------------------------- */
export const handleThemeTrue = (element, theme) => {
    element.classList.add(`theme__${theme}`);
    handleToggleColors(darkMode);
}

/* --------------------------------------------------------------------------
| FUNCTION -> SETA O TEMA COMO FALSE...
|-------------------------------------------------------------------------- */
export const handleThemeFalse = (element, theme) => {
    element.classList.remove(`theme__${theme}`);
    handleToggleColors(lightMode);
}


