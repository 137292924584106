/*
|--------------------------------------------------------------------------
|------------------------| FUNCTIONS ALERTS |------------------------------
|--------------------------------------------------------------------------
*/

import { handleAddOrRemoveClass } from "./functions-helpers";

/* --------------------------------------------------------------------------
| FUNCTION -> SELECIONA O ALERTA...
|-------------------------------------------------------------------------- */
export const handleSelectedAlert = () => {
    let alert = document.querySelector('.alert');
    return alert;
}

/* --------------------------------------------------------------------------
| FUNCTION -> ABRE OU FECHA O ALERTA...
|-------------------------------------------------------------------------- */
export const handleAlertToggleOpenOrClose = (type, alert) => {
	if (type === 'open') {
		handleAddOrRemoveClass(alert, 'remove', 'alert--close');
		handleAddOrRemoveClass(alert, 'add', 'alert--show');

	} else if (type === 'close') {
		handleAddOrRemoveClass(alert, 'remove', 'alert--show');
		handleAddOrRemoveClass(alert, 'add', 'alert--close');
	}
}

/* --------------------------------------------------------------------------
| FUNCTION -> FECHA O ALERTA POR TEMPO...
|-------------------------------------------------------------------------- */
export const handleAlertTimeOutClose = (alert, time = 3000) => {
    if (alert.classList.contains('alert--timeout')) {
        setTimeout(() => {
			handleAlertToggleOpenOrClose('close', alert);
        }, time);
    }
}

/* --------------------------------------------------------------------------
| FUNCTION -> ALTERNA O ALERTA ENTRE ABRETO E FECHADO...
|-------------------------------------------------------------------------- */
export const handleToggleAlert = () => {
    let alert = handleSelectedAlert();

    (alert.classList.contains('alert--show')) // Verifica se o display é igual a none...
        ? handleAlertToggleOpenOrClose('close', alert) // false - chama a função de close...
        : handleAlertToggleOpenOrClose('open', alert); // true - chama a função de open...
}
